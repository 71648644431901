/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import { Modal, ModalBody, Form, Label, Spinner } from "reactstrap";
import { Icon, Col, Button } from "../../../components/Component";
import DatePicker from "react-datepicker";
import useEventForm from "../form/use-event-form";
import { Controller } from "react-hook-form";

const DateItemPicker = ({ index, control, item, errors, fields, remove }) => {
  return (
    <div
      key={item.id}
      style={{
        display: "flex",
        alignItems: "start",
        justifyContent: "space-between",
      }}
    >
      <Col md="5">
        <div className="form-group">
          <Label className="form-label" htmlFor="from">
            Date
          </Label>

          <Controller
            name={`dates[${index}].date`}
            control={control}
            render={({ field }) => (
              <div>
                <div className="form-control-wrap">
                  <div className="input-group">
                    <DatePicker
                      {...field}
                      selected={field.value}
                      onChange={(date) => field.onChange(date)}
                      showTimeCaption={false}
                      dateFormat="dd/MM/yyyy"
                      className="form-control date-picker"
                    />
                  </div>
                </div>
                {errors.dates &&
                  errors.dates[index] &&
                  errors.dates[index].date && (
                    <span className="invalid-message">
                      {errors.dates[index].date.message}
                    </span>
                  )}
              </div>
            )}
          />
        </div>
      </Col>
      <Col md="3">
        <div className="form-group">
          <Label className="form-label" htmlFor="to">
            Start Time
          </Label>

          <Controller
            name={`dates[${index}].from`}
            control={control}
            render={({ field }) => (
              <div>
                <div className="form-control-wrap">
                  <div className="input-group">
                    <DatePicker
                      {...field}
                      selected={field.value}
                      onChange={(date) => field.onChange(date)}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={15}
                      showTimeCaption={false}
                      timeFormat="HH:mm"
                      dateFormat="HH:mm"
                      className="form-control date-picker"
                    />
                  </div>
                </div>
                {errors.dates &&
                  errors.dates[index] &&
                  errors.dates[index].from && (
                    <span className="invalid-message">
                      {errors.dates[index].from.message}
                    </span>
                  )}
              </div>
            )}
          />
        </div>
      </Col>
      <Col md="3">
        <div className="form-group">
          <Label className="form-label" htmlFor="to">
            End Time
          </Label>

          <Controller
            name={`dates[${index}].to`}
            control={control}
            render={({ field }) => (
              <div>
                <div className="form-control-wrap">
                  <div className="input-group">
                    <DatePicker
                      {...field}
                      selected={field.value}
                      onChange={(date) => field.onChange(date)}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={15}
                      showTimeCaption={false}
                      timeFormat="HH:mm"
                      dateFormat="HH:mm"
                      className="form-control date-picker"
                    />
                  </div>
                </div>
                {errors.dates &&
                  errors.dates[index] &&
                  errors.dates[index].to && (
                    <span className="invalid-message">
                      {errors.dates[index].to.message}
                    </span>
                  )}
              </div>
            )}
          />
        </div>
      </Col>
      <Icon
        name="trash"
        style={{ marginTop: 40, cursor: "pointer" }}
        onClick={() => remove(index)}
        disabled={fields.length === 1}
      ></Icon>
    </div>
  );
};

const UpdateEventModal = ({ isOpen, closeModal, event }) => {
  const {
    fields,
    append,
    remove,
    form,
    control,
    onFormSubmit,
    uploadImage,
    isLoadingImage,
    image,
  } = useEventForm(event, { closeModal });
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = form;

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => closeModal()}
      className="modal-dialog-centered"
      size="xl"
    >
      <ModalBody>
        <a
          href="#cancel"
          onClick={(ev) => {
            ev.preventDefault();
            closeModal();
          }}
          className="close"
        >
          <Icon name="cross-sm"></Icon>
        </a>
        <div className="p-2">
          <h5 className="title">{event != null ? "Update" : "Add"} Event</h5>
          <div className="mt-4">
            <Form
              className="row gy-4 form-validate"
              noValidate
              onSubmit={handleSubmit(onFormSubmit)}
            >
              <Col md="12">
                <div className="form-group">
                  <Label className="form-label" htmlFor="fv-title">
                    Title
                  </Label>
                  <div className="form-control-wrap">
                    <input
                      type="text"
                      id="fv-title"
                      {...register("name")}
                      className="form-control"
                    />
                    {errors.name && (
                      <span className="invalid">{errors.name.message}</span>
                    )}
                  </div>
                </div>
              </Col>
              <Col md="12">
                <div className="form-group">
                  <Label className="form-label" htmlFor="fv-desc">
                    Description
                  </Label>
                  <div className="form-control-wrap">
                    <textarea
                      type="textarea"
                      className="form-control form-control-sm"
                      id="fv-desc"
                      {...register("desc")}
                    />
                    {errors.desc && (
                      <span className="invalid">{errors.desc.message}</span>
                    )}
                  </div>
                </div>
              </Col>
              {fields.map((item, index) => (
                <DateItemPicker
                  key={index}
                  index={index}
                  control={control}
                  item={item}
                  errors={errors}
                  fields={fields}
                  remove={remove}
                />
              ))}

              <div className="align-center flex-wrap flex-sm-nowrap">
                <Button
                  color="primary"
                  size="md"
                  type="button"
                  onClick={() => append({ date: null, from: null, to: null })}
                >
                  Add date
                </Button>
                {errors.dates && (
                  <span className="invalid-message" style={{ marginLeft: 10 }}>
                    {errors.dates.message}
                  </span>
                )}
              </div>

              <Col md="6">
                <div className="form-group">
                  <Label className="form-label" htmlFor="fv-location">
                    Location
                  </Label>
                  <div className="form-control-wrap">
                    <input
                      type="text"
                      id="fv-location"
                      {...register("location")}
                      className="form-control"
                    />
                    {errors.location && (
                      <span className="invalid">{errors.location.message}</span>
                    )}
                  </div>
                </div>
              </Col>
              <Col md="6">
                <div className="form-group">
                  <Label className="form-label" htmlFor="fv-link">
                    Link
                  </Label>
                  <div className="form-control-wrap">
                    <input
                      type="text"
                      id="fv-link"
                      {...register("link")}
                      className="form-control"
                    />
                    {errors.link && (
                      <span className="invalid">{errors.link.message}</span>
                    )}
                  </div>
                </div>
              </Col>
              <Col sm="12">
                <div className="form-group">
                  <label className="form-label">Image</label>
                  <div className="form-control-wrap">
                    <input
                      type="file"
                      id="image"
                      multiple={false}
                      hidden
                      accept="image/png, image/gif, image/jpeg"
                      onChange={(e) => {
                        if (e.target.files) {
                          const file = e.target.files[0];
                          const data = new FormData();
                          data.append("image", file);
                          uploadImage(data);
                        }
                      }}
                    />
                    <label htmlFor="image">
                      {isLoadingImage ? (
                        <div className="w-150px h-150px d-flex align-items-center justify-content-center border">
                          <Spinner />
                        </div>
                      ) : (
                        <div className="w-150px h-150px d-flex align-items-center justify-content-center border">
                          {image ? (
                            <img
                              src={image}
                              width={150}
                              height={150}
                              alt="image"
                              style={{
                                objectFit: "cover",
                              }}
                            />
                          ) : (
                            <Icon
                              name="upload"
                              style={{
                                fontSize: "30px",
                              }}
                            />
                          )}
                        </div>
                      )}
                    </label>
                  </div>
                  {errors.image && !image && (
                    <span className="invalid-message">
                      {errors.image.message}
                    </span>
                  )}
                </div>
              </Col>
              <Col size="12">
                <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                  <li>
                    <Button color="primary" size="md" type="submit">
                      {isSubmitting ? (
                        <Spinner size="sm" color="light" />
                      ) : (
                        "Save"
                      )}
                    </Button>
                  </li>
                  <li>
                    <a
                      href="#cancel"
                      onClick={(ev) => {
                        ev.preventDefault();
                        closeModal();
                      }}
                      className="link link-light"
                    >
                      Cancel
                    </a>
                  </li>
                </ul>
              </Col>
            </Form>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};
export default UpdateEventModal;

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm, useFieldArray } from "react-hook-form";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { uploadFile } from "../../../api/upload-file";
import { addEvent, updateEvent } from "../../../api/event";
import { addEventSuccess, updateEventSuccess } from "../../../slice/events";
import { toast } from "react-toastify";
import moment from "moment";

const parseEventToForm = (event) => {
  if (event) {
    if (event.dates) {
      const dates = event.dates.map((item) => {
        return {
          date: moment(item.from).toDate(),
          from: moment(item.from).toDate(),
          to: moment(item.to).toDate(),
        };
      });
      return { ...event, dates };
    } else {
      return { ...event, dates: [] };
    }
  } else {
    return {};
  }
};

const useEventForm = (event, { closeModal }) => {
  const schema = yup
    .object({
      name: yup.string().required("Name is required"),
      desc: yup.string().required("Description is required"),
      image: yup.string().required("Image is required"),
      location: yup.string().required("Location is required"),
      link: yup.string(),
      dates: yup
        .array()
        .required("At least one date is required")
        .min(1, "At least one date is required")
        .of(
          yup.object({
            date: yup
              .date()
              .required("Date is required")
              .typeError("Invalid date"),
            from: yup
              .date()
              .required("Start time is required")
              .typeError("Invalid start time"),
            to: yup
              .date()
              .required("End time is required")
              .typeError("Invalid end time")
              .min(yup.ref("from"), "End time cannot be before the start time"),
          })
        ),
    })
    .required();

  const defaultValues = parseEventToForm(event);

  const form = useForm({
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });

  const { control, watch, setValue, reset } = form;

  // useFieldArray hook for handling dynamic fields
  const { fields, append, remove } = useFieldArray({
    control,
    name: "dates",
  });

  const onFormSubmit = async (data) => {
    const dates = data.dates.map((item) => {
      var fromStr =
        moment(item.date).format("DD-MM-YYYY") +
        " " +
        moment(item.from).format("HH:mm");
      var toStr =
        moment(item.date).format("DD-MM-YYYY") +
        " " +
        moment(item.to).format("HH:mm");
      return {
        from: moment(fromStr, "DD-MM-YYYY HH:mm").toISOString(),
        to: moment(toStr, "DD-MM-YYYY HH:mm").toISOString(),
      };
    });
    if (event) {
      await onUpdateEvent({ ...data, dates });
    } else {
      await onAddEvent({ ...data, dates });
    }
  };

  const image = watch("image");
  useEffect(() => {
    reset({
      ...parseEventToForm(event),
    });
  }, [reset, event]);
  ///
  const dispatch = useDispatch();
  const [isLoadingImage, setLoadingImage] = useState(false);
  const uploadImage = async (payload) => {
    setLoadingImage(true);
    try {
      const res = await uploadFile(payload);
      setValue("image", res.fileUrl);
    } catch (error) {
      toast.error(error);
    }
    setLoadingImage(false);
  };
  const onAddEvent = async (data) => {
    try {
      const res = await addEvent(data);
      dispatch(addEventSuccess(res));
      toast.success("Add event successfully");
      closeModal();
    } catch (error) {
      toast.error(error?.message);
    }
  };

  const onUpdateEvent = async (data) => {
    try {
      const res = await updateEvent(event._id, data);
      dispatch(updateEventSuccess(res));
      toast.success("Update event successfully");
      closeModal();
    } catch (error) {
      toast.error(error?.message);
    }
  };
  return {
    form,
    onFormSubmit,
    fields,
    append,
    remove,
    control,
    isLoadingImage,
    image,
    uploadImage,
  };
};

export default useEventForm;
